import {HTTP} from '@/libs/https.js';
import config from '@/libs/config.js';

class CampusSafetyModel extends HTTP {

    getOutInListData(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_OUT_IN_LIST_DATA,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    getCountData(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_OUT_IN_COUNT_DATA,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getIssueScopeByAccess(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_ISSUE_SCOPE_BY_ACCESS,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

     // 导入
     uploadLeaveRecord(data) {
        return new Promise((resolve, reject) => {
            this.axiosFile({
                url: config.API.UPLOAD_ISSUE_SCOPE_RECORD,
                data: data,
                header: "application/json",
                success(data) {
                    resolve({
                        data,
                        status: 0,
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1,
                    });
                },
            });
        });
    };

    // 批量设置接送人
    setBatchSettingPickUp(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.BATCH_SETTING_PICK_UP,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    // 获取推送通知
    getPushNotification(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_PUSH_NOTICE_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    // 修改推送通知
    updatePushNotification(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.UPDATE_PUSH_NOTICE_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getAccessGroupList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_ACCESS_GROUP_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    saveAccessGroup(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SAVE_ACCESS_GROUP,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    deleteAccessGroup(data) {
        return new Promise((resolve, reject) => {
            this.axiosPostArray({
                url: config.API.DELETE_ACCESS_GROUP,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    isAbleAccessGroup(data) {
        return new Promise((resolve, reject) => {
            this.axiosPostArray({
                url: config.API.IS_ENABLE_ACCESS_GROUP,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getAccessRecordList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_ACCESS_RECORD_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getParentDirectoryList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_PARENT_DIRECTORY_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getParentDirectoryList2(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_PARENT_DIRECTORY_LIST2,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    
    deleteParentDirectory(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.DELETE_PARENT_DIRECTORY,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    saveParentDirectory(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SAVE_PARENT_DIRECTORY,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    isPickUp(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.IS_PICK_UP,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getSchoolOrgan(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SCHOOL_ORGAN,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    downloadParentDirectory(data) {
        return new Promise((resolve, reject) => {
            this.axiosGet({
                url: config.API.DOWNLOAD_PARENT_DIRECTORY,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    getMessageSetting(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_MESSAGE_SETTING,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getTeacherByIds(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_TEACHERS_INFO,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    saveMessageSetting(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SAVE_MESSAGE_SETTING,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    conditionByList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.CONDITION_BY_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    conditionSave(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.CONDITION_SAVE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getSchoolOrganizationTree (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SCHOOL_ORGANIZATION_TREE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getTeachersById (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_TEACHERS_LIST_BY_ID,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    uploadImportPerson(data) {
        return new Promise((resolve, reject) => {
            this.axiosFile({
                url: config.API.IMPORT_PERSON,
                data: data,
                header: "application/json",
                success(data) {
                    resolve({
                        data,
                        status: 0,
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1,
                    });
                },
            });
        });
    };

    segemtConditionMenuTreeList (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SEGEMT_CONDITION_MENU_TREE_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getGroupTimeType(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_GROUP_TIME_TYPE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    setGroupTimeType(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SET_GROUP_TIME_TYPE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    
    getGroupPersonList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_GROUP_PERSON_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    saveAccessGroupPerson(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SAVE_ACCESS_GROUP_PERSON,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getAccessGroupDoNotPrompt(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_ACCESS_GROUP_DO_NOT_PROMPT,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    setAccessGroupDoNotPrompt(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SET_ACCESS_GROUP_DO_NOT_PROMPT,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    deleteAccessPerson(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.DELETE_ACCESS_PERSON,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getAccessAbnormalSettingList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_ACCESS_ABNORMAL_SETTING_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    saveAccessAbnormalSetting(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SAVE_ACCESS_ABNORMAL_SETTING,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
}

export {CampusSafetyModel};
